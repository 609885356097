<template>
    <div class="card-body p-lg-5">
        <div class="row">
             <div class="col-lg-12">
                 <h1 class="font-xxl fw-800 mb-lg-5">Settings</h1>
                 <div v-for="(item) in menuList" :key="item.title">
                      <div class="nav-caption fw-600 font-xssss text-grey-500 mb-2">{{item.title}}</div>
                       <ul class="list-inline mb-4">
                            <li class="list-inline-item d-block me-0" v-for="(subitem ,idx) in item.subList" :key="subitem.url" :class="(idx==(item.subList.length-1))?subitem.length:'border-bottom'">
                                <router-link :to="subitem.url" class="pt-2 pb-2 d-flex align-items-center" v-if="!subitem.external">
                                    <i class="btn-round-md text-white font-md me-3 lh2" :class="subitem.icon+' '+subitem.iconClass"></i>
                                    <h4 class="fw-600 font-xsss mb-0 mt-0">{{subitem.title}}</h4><i class="mico-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                </router-link>
                                <a class="pt-2 pb-2 d-flex align-items-center" v-else :href="subitem.url" target="_blank">
                                    <i class="btn-round-md text-white font-md me-3 lh2" :class="subitem.icon+' '+subitem.iconClass"></i>
                                    <h4 class="fw-600 font-xsss mb-0 mt-0">{{subitem.title}}</h4><i class="mico-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                </a>
                            </li>
                       </ul>
                 </div>
             </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            menuList:[
                {
                    title:'General',
                    subList:[
                        { url:'/settings/editprofile' , title:'Account Information' , icon:'mico-user-outline' , iconClass:'bg-primary-gradiant'},
                        { url:'/settings/wallets' , title:'Connected Wallets' , icon:'mico-vallet' , iconClass:'bg-gold-gradiant'},
                        { url:'/settings/password' , title:'Password' , icon:'mico-key' , iconClass:'bg-red-gradiant'}
                    ]
                },
                {
                    title:'Other',
                    subList:[
                         { url:'https://memecake.notion.site/memecake/Meme-Cake-Help-Center-9a712a74a45643cba080550e8041e3b9', external:true, title:'Help' , icon:'mico-fa-regular-400' , iconClass:'bg-blue-gradiant'},
                         { url:'/logout' , title:'Logout' , icon:'mico-sign-out' , iconClass:'bg-red-gradiant'}
                    ]
                }
            ]
        }
    }
}
</script>